<template>
    <div>
        <h1>Batch Utilization</h1>
        <form @submit.prevent="runReport">
            <Options />
            <Results />
        </form>
    </div>
</template>

<script>
    import { store } from '@/store/Report.store.js';
    import Options from './batch_utilization_opt';
    import Results from './utils/Results';

    export default  {
        name: 'batch_utilization',
        components : {
            Options,
            Results
        },
        data() {
            return {
                state: store.state,
                optionFields: {
                    batchFrom: {val: new Date(new Date().setDate(1)).toLocaleDateString(), qsField: '[datetimes][bu.saved_date_from]'},
                    batchTo: {val: new Date().toLocaleDateString(), qsField: '[datetimes][bu.saved_date_to]'},
                    machine: {val: '0', qsField: '[strings][tg.machine_name]'},
                    cdiLocationID: {val: '0', qsField: '[numbers][cl.cdi_location_id]'},
                    batch: {val: '', qsField: '[strings][bu.batch_number]'},
                    userName: {val: '', qsField: '[strings][bu.username]'},
                    itemized: {val: 0, qsField: '[itemized]'}
                },
                groupByFields: {},
                dataFields: {}
            }
        },
        methods: {
            runReport: function() {
                store.runReport();
            }
        },
        created() {
            this.$appStore.setTitle(['Batch Utilization']);
            this.$set(store.state.results, this.$options.name, '');
            store.setReport(this.$options.name, this.optionFields, this.groupByFields, this.dataFields);
        }
    }
</script>