<template>
    <div class='panel panel-default'>
        <div class='panel-heading'></div>
        <div class='row panel-body'>
            <div class='date_picker col-md-3 col-sm-4 col-xs-6 form-group'>
                <label>From</label><DatePicker :input-attributes="{ class: 'form-control'}" v-model="state.optionFields.batchFrom.val" />
            </div>
            <div class='date_picker col-md-3 col-sm-4 col-xs-6 form-group'>
                <label>To</label><DatePicker :input-attributes="{ class: 'form-control'}" v-model="state.optionFields.batchTo.val" />
            </div>
            <div class='col-md-3 col-sm-4 col-xs-6 form-group'>
                <label>Machine</label>
                <select class="form-control" v-model="state.optionFields.machine.val">
                    <option value="0">All</option>
                    <option v-for="(machineName, index) in cache.machineCache.MACHINES" v-bind:key="index" :value="machineName">{{machineName}}</option>
                </select>
            </div>
            <div class='col-md-3 col-sm-4 col-xs-6 form-group'>
                <label>CDI Location</label>
                <select class="form-control" v-model="state.optionFields.cdiLocationID.val">
                    <option value="0">All</option>
                    <option v-for="[cdiLocationID, cdiLocation] in cache.cdiLocationCache.CDI_LOCATIONS" v-bind:key="cdiLocationID" :value="cdiLocationID">{{cdiLocation}}</option>
                </select>
            </div>
            <div class='col-md-3 col-sm-4 col-xs-6 form-group'>
                <label>Batch</label><input type='text' class='form-control' v-model="state.optionFields.batch.val" />
            </div>
            <div class='col-md-3 col-sm-4 col-xs-6 form-group'>
                <label>Username</label><input type='text' class='form-control' v-model="state.optionFields.userName.val" />
            </div>
            <div class='col-md-3 col-sm-4 col-xs-6 form_grid'>
                <label><input type='checkbox' :true-value="1" :false-value="0" v-model="state.optionFields.itemized.val"> Itemized</label>
            </div>
        </div>
    </div>
</template>

<script>
    import { store } from '@/store/Report.store.js';
    import cdiLocationCache from '@/cache/cdiLocation.cache.js';
    import machineCache from '@/cache/machine.cache.js';
    import DatePicker from "@/components/utils/DatePicker";
    
    export default {
        name: "Options",
        components: { DatePicker },
        data() {
            return {
                state: store.state,
                cache: {
                    cdiLocationCache,
                    machineCache
                },
            }
        }
    }
</script>

<style scoped>

</style>