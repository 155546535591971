export default {
    MACHINES: [
        'G30 1225',
        'G30 1525',
        '1250S',
        '1250 MXP',
        '1525M',
        'Phoenix Laser',
        'Lonestar Laser'
    ]
};